<template>
  <div fp-title v-if="title">
    <button v-if="back" @click="goBack">
      <FontIcon name="arrow-left" />
    </button>
    <h3>{{ $t(title) }}</h3>
  </div>
</template>

<script>
import FontIcon from '@shared/components/common/FontIcon.vue';
export default {
  name: 'FpTitle',
  components: { FontIcon },
  props: {
    title: { type: String, default: null },
    back: Boolean,
    step: { type: Number, default: 0 },
    appName : {type : String, default : null,}
  },
  methods: {
    goBack() {
      if(this.appName === 'findId'){
        this.$router.back();
      }else{
        const step = this.step - 1;
        this.$emit('change-step', step);
      }

    }
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[fp-title] { .rel(); .pt(32);
  > button { .abs(); .lt(0, 0);
    [font-icon] { .fs(24); .c(white); }
  }
  h3 { .fs(32, 38); .regular();}
}
</style>